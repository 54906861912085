import "./PaymentWidget.scss";
import React from "react";
import { Button } from "react-bootstrap";
import { getToken, getRedirectUrl } from "../components/auth";
import {
    getAxiosPaymentInstance,
    numberToMoneyFormat,
    isApp,
    paymentChannels,
    PaymentChannel,
} from "../components/helper";
import { TransactionModel } from "../models/TransactionModel";
import { PaymentChannelsWidget } from "./PaymentChannelsWidget";
import QRCode from "react-qr-code";
import Swal from "sweetalert2";
import BankInfoWidget from "./BankInfoWidget";
import { FormattedMessage, IntlShape, injectIntl } from "react-intl";

type TransactionProperties = {
    intl: IntlShape;
    transaction: any;
    onTransactionUpdate: Function;
};

var timer: NodeJS.Timeout | undefined;

class PaymentWidgetWithIntl extends React.Component<TransactionProperties> {
    private intl = this.props.intl;
    private transaction: TransactionModel;
    private selectedPaymentChannelId: number = 0;
    state = {
        isDelayRetry: false,
        isFormLoading: false,
        isFormInvalid: true,
        currentVAKey: 0,
        isProcessingPayment: false,
        churchesVa: undefined,
        vaNumber: "",
        showAllChannel: false,
        channels: [] as PaymentChannel[],
    } as any;
    private onlyChannelId?: number[] = undefined;
    private cashPayment: boolean = false;

    loadPaymentChannels = async () => {
        let provider = "FASPAY";
        if (
            this.transaction.bill_no.indexOf("/EK-KAOS/") > 0 ||
            this.transaction.bill_no.indexOf("/GMS-DUMMY/") > 0
        ) {
            provider = "IFORTEPAY";
        }
        let channels = await paymentChannels(provider);
        // khusus dummy, filter pembayaran hanya menggunakan milik iforte
        // if (this.transaction.bill_no.indexOf("/GMS-DUMMY/") > 0) {
        //     channels = channels.filter((o) => o.provider === "IFORTEPAY");
        // }
        this.setState({
            channels: channels,
        });
    };

    constructor(props: any) {
        super(props);
        this.transaction = new TransactionModel(props.transaction);
        this.state.showAllChannel = !this.transaction.payment_channel_id;
        if (this.transaction.bill_no.includes("/AOG-CF2")) {
            this.cashPayment = true;
            this.onlyChannelId = [1, 3, 5, 6, 7];
        } else if (this.transaction.bill_no.includes("/MSJ-ODR")) {
            this.onlyChannelId = [7];
        }
    }

    componentDidMount() {
        this.loadPaymentChannels();
        if (
            this.transaction.payment_channel_id &&
            this.transaction.is_bank_transfer
        ) {
            this.setState({
                vaNumber: this.transaction.va_number ?? this.transaction.trx_id,
            });
        }
    }

    triggerPhoneInputBeforePayment = () => {
        Swal.fire({
            title: this.intl.formatMessage({
                id: "enterPhoneNumber",
                defaultMessage: "Masukkan nomor handphone",
            }),
            showCancelButton: true,
            confirmButtonText: this.intl.formatMessage({
                id: "confirm",
                defaultMessage: "Konfirmasi",
            }),
            input: "tel",
            inputAttributes: {
                autocapitalize: "off",
                pattern: "[0-9]*",
            },
            validationMessage: this.intl.formatMessage({
                id: "phoneNumberRequired",
                defaultMessage: "Nomor handphone harus diisi",
            }),
            cancelButtonText: this.intl.formatMessage({
                id: "cancel",
                defaultMessage: "Batal",
            }),
            preConfirm: (phoneNumber: string) => {
                console.log(phoneNumber);
                if (!phoneNumber) {
                    Swal.showValidationMessage(
                        this.intl.formatMessage({
                            id: "phoneNumberRequired",
                            defaultMessage: "Nomor handphone harus diisi",
                        })
                    );
                } else {
                    return this.proceedPayment(phoneNumber);
                }
            },
        }).then((result) => {});
    };

    handleImageUpload = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        const imageType = file.type;
        const supportedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!supportedTypes.includes(imageType)) {
            Swal.fire(
                "Format gambar tidak didukung",
                "Silahkan pilih file dengan format .jpg, .jpeg, atau .png.",
                "warning"
            );
            return;
        }
        Swal.fire({
            title: "Upload bukti pembayaran?",
            icon: "question",
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            showCancelButton: true,
            preConfirm: () => {
                var formData = new FormData();
                formData.append("file", file);
                const instance = getAxiosPaymentInstance(getToken());
                return instance
                    .post("payment/receipt-upload", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        params: {
                            bill_no: this.transaction.bill_no,
                        },
                    })
                    .then((result) => {
                        if (result.data.result.image_url) {
                            this.transaction.payment_receipt_url =
                                result.data.result.image_url;
                            this.setState({});
                            Swal.fire(
                                "Success",
                                "Bukti Pembayaran berhasil di upload",
                                "success"
                            );
                        }
                    })
                    .catch((err) => {
                        console.error("Error at image upload!");
                        console.error(err);
                        Swal.fire({
                            title: "Gagal melakukan upload bukti pembayaran!",
                            text: "Silahkan mencoba beberapa saat lagi atau coba ulang dengan ukuran gambar yang lebih kecil.",
                            icon: "error",
                        });
                    });
            },
        }).then((confirm) => {
            if (confirm.isDenied || confirm.isDismissed) {
                // kosongkan input bila dibatalkan
                e.target.value = null;
            }
        });
    };

    formatVaNumber = (vaNumber: string) => {
        return (
            vaNumber.substring(0, 3) +
            " " +
            vaNumber.substring(3, 6) +
            " " +
            vaNumber.substring(6, 9) +
            " " +
            vaNumber.substring(9, 12) +
            " " +
            vaNumber.substring(12)
        );
    };

    componentDidUpdate = (prevProps: any, prevState: any) => {
        // kalau ada perubahan nomor VA
        if (this.state.vaNumber !== prevState.vaNumber) {
            // scroll ke bagian paling atas
            window.scrollTo(0, 0);
        }
    };

    proceedPayment = async (phoneNumber?: string) => {
        Swal.fire({
            title: "Processing your registration",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading(null);
            },
        });
        this.setState({
            isFormLoading: true,
            showAllChannel: false,
        });
        let payload: any = {
            bill_no: this.transaction.bill_no,
            payment_channel_id: this.selectedPaymentChannelId,
        };
        if (phoneNumber) {
            this.setState({
                isDelayRetry: true,
            });
            // jika sudah ada timer, hapus dulu
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                this.setState({
                    isDelayRetry: false,
                });
            }, 30000);
            payload.phone_number = phoneNumber;
        }
        const instance = getAxiosPaymentInstance(getToken());
        const endpoint = this.retryPayment
            ? "/payment/retry"
            : "/create-payment-inquiry";
        const q = this.retryPayment
            ? instance.get(endpoint, {
                  params: payload,
              })
            : instance.post(endpoint, payload);
        try {
            let res = await q;
            let result = res.data.result;
            // dapat url redirect
            if (result.transaction_type === "PAYMENT_GATEWAY_REDIRECT") {
                this.setState({
                    isProcessingPayment: true,
                });
                console.debug("Redirect to: " + result.redirect_url);
                if (result.deeplink) {
                    if (isApp()) {
                        window.location.href =
                            result.deeplink + "?target=_blank";
                    } else {
                        window.location.href = result.deeplink;
                    }
                } else {
                    window.location.href = result.redirect_url;
                }
            } else {
                this.transaction.payment_channel_id =
                    this.selectedPaymentChannelId;
                if (result.web_url) {
                    this.transaction.qrcode_url = result.web_url;
                } else if (result.qrcode_url) {
                    this.transaction.qrcode_url = result.qrcode_url;
                }
                if (result.bill_total) {
                    this.transaction.bill_total = result.bill_total;
                }
                const channel = this.state.channels.find(
                    (o: any) => o.id === this.selectedPaymentChannelId
                );
                this.transaction.payment_channel_name = channel.name;
                this.transaction.is_bank_transfer = channel?.is_bank_transfer;
                if (channel && channel.is_bank_transfer) {
                    this.setState({
                        vaNumber: result.trx_id,
                    });
                }
                if (this.props.onTransactionUpdate) {
                    this.props.onTransactionUpdate(this.transaction);
                }
                // kembalikan state seperti semula
                this.setState({
                    isFormLoading: false,
                    isFormInvalid: true, // supaya tombol tidak bisa ditekan lagi
                });
            }
        } catch (exc) {
            console.error("Error at submit");
            console.error(exc);
            this.setState({
                isFormLoading: false,
            });
            Swal.fire(
                "Failed",
                "Request failed, please try again later or contact support!",
                "error"
            );
        } finally {
            Swal.close();
        }
    };

    submit = async (event: any) => {
        event.preventDefault();
        if (this.state.isFormLoading) {
            return;
        }
        // sementara di paten, karena CASH tidak perlu update ke server
        if (
            this.selectedPaymentChannelId ===
                this.transaction.payment_channel_id &&
            (this.selectedPaymentChannelId === 5 ||
                this.selectedPaymentChannelId === 6)
        ) {
            return;
        }
        // OVO perlu input no HP
        if (this.selectedPaymentChannelId === 270) {
            await this.triggerPhoneInputBeforePayment();
        } else {
            await this.proceedPayment();
        }
    };

    onPaymentChannelUpdate = (paymentChannelId: number) => {
        // animate scroll
        this.selectedPaymentChannelId = paymentChannelId;
        this.setState({
            isFormInvalid: false,
        });
    };

    get retryPayment(): boolean {
        return this.transaction.payment_channel_id ? true : false;
    }

    renderCurrentPaymentMethod() {
        if (
            this.transaction.payment_channel_id === 2 ||
            this.transaction.payment_channel_name === "QRIS"
        ) {
            return (
                <div>
                    <div>QRIS</div>
                    <hr />
                    <div className="text-center">
                        <div className="qrcode-wrapper">
                            <QRCode
                                value={this.transaction.qrcode_url}
                                size={128}
                            ></QRCode>
                        </div>
                        <div>
                            {this.transaction.bill_no}
                            <br />
                            {numberToMoneyFormat(this.transaction.bill_total)}
                        </div>
                    </div>
                    <hr />
                    Dicek dalam 10 menit setelah pembayaran berhasil
                    <br />
                    <br />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec sit amet libero eu urna porta efficitur in in turpis.
                    Nam venenatis turpis lacus, a vulputate nisl pulvinar ac.
                    <hr />
                </div>
            );
        } else if (
            this.transaction.payment_channel_id === 5 ||
            (this.transaction.payment_channel_id &&
                this.transaction.is_bank_transfer)
        ) {
            return (
                <div>
                    <BankInfoWidget
                        channels={this.state.channels}
                        paymentChannelId={this.transaction.payment_channel_id}
                        vaNumber={this.state.vaNumber}
                    ></BankInfoWidget>
                </div>
            );
        } else if (this.transaction.payment_channel_id === 6) {
            return (
                <div>
                    Pembayaran Cash
                    <div className="text-center">
                        <div className="qrcode-wrapper">
                            <QRCode
                                value={this.transaction.bill_no}
                                size={128}
                            ></QRCode>
                        </div>
                        <div>
                            {this.transaction.bill_no}
                            <div className="bill-total">
                                {numberToMoneyFormat(
                                    this.transaction.bill_total
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <ul style={{ paddingLeft: "15px" }}>
                        <li>
                            Pembayaran <b>hanya</b> dapat dilakukan{" "}
                            <b>sebelum</b> dan <b>sesudah jam ibadah</b>.
                        </li>
                        <li>
                            Silahkan <b>tunjukkan QR Code kepada operator</b>{" "}
                            yang bertugas.
                        </li>
                        <li>
                            Silahkan melakukan{" "}
                            <b>pembayaran sebelum batas waktu</b> yang
                            ditentukan.
                        </li>
                        <li>
                            Silahkan melakukan pembayaran <b>tepat</b> sesuai
                            dengan total tagihan.
                        </li>
                    </ul>
                </div>
            );
        }
    }

    renderTransactionDetail() {
        if (this.transaction.status === "PAID") {
            return (
                <div>
                    <span className="float-left">Payment Method:</span>
                    <span className="float-right">
                        <b>{this.transaction.payment_channel_name}</b>
                    </span>
                    <div className="clearfix"></div>
                    <span className="float-left">Paid At:</span>
                    <span className="float-right">
                        <b>
                            {this.transaction.paid_at?.format(
                                "D MMM YYYY HH:mm"
                            )}
                        </b>
                    </span>
                    <div className="clearfix"></div>
                </div>
            );
        } else if (
            this.transaction.status === "EXPIRED" ||
            this.transaction.status === "CANCELED"
        ) {
            return (
                <div>
                    <span className="float-left">Payment Method:</span>
                    <span className="float-right">
                        <b>{this.transaction.payment_channel_name}</b>
                    </span>
                    <div className="clearfix"></div>
                </div>
            );
        } else {
            if (
                !this.selectedPaymentChannelId &&
                this.transaction.payment_channel_id
            ) {
                this.selectedPaymentChannelId =
                    this.transaction.payment_channel_id;
            }
            if (this.state.channels.length > 0) {
                return (
                    <div>
                        {this.renderCurrentPaymentMethod()}
                        {this.renderPaymentForm()}
                    </div>
                );
            } else {
                return <>Loading</>;
            }
        }
    }

    renderPaymentForm() {
        if (this.transaction.payment_channel_id) {
            // kalau hanya 1, tidak perlu tombol update
            if (this.onlyChannelId && this.onlyChannelId.length === 1) {
                return <></>;
            } else {
                return (
                    <form onSubmit={this.submit}>
                        <PaymentChannelsWidget
                            onlyChannelId={this.onlyChannelId}
                            key={this.transaction.bill_no}
                            transId={this.transaction.bill_no}
                            channels={this.state.channels}
                            paymentChannelId={
                                this.transaction.payment_channel_id
                            }
                            showAll={this.state.showAllChannel}
                            onPaymentChannelUpdate={this.onPaymentChannelUpdate}
                            showCash={this.cashPayment}
                        ></PaymentChannelsWidget>
                        {this.renderPayButton(
                            this.intl.formatMessage({
                                id: "pay",
                                defaultMessage: "Bayar",
                            })
                        )}
                    </form>
                );
            }
        } else {
            return (
                <form onSubmit={this.submit}>
                    <PaymentChannelsWidget
                        onlyChannelId={this.onlyChannelId}
                        key={this.transaction.bill_no}
                        transId={this.transaction.bill_no}
                        channels={this.state.channels}
                        paymentChannelId={this.transaction.payment_channel_id}
                        showAll={this.state.showAllChannel}
                        onPaymentChannelUpdate={this.onPaymentChannelUpdate}
                        showCash={this.cashPayment}
                    ></PaymentChannelsWidget>
                    {this.renderPayButton(
                        this.intl.formatMessage({
                            id: "pay",
                            defaultMessage: "Bayar",
                        })
                    )}
                </form>
            );
        }
    }

    renderPayButton(label: string) {
        let currentChannel: PaymentChannel | undefined;
        if (this.transaction.payment_channel_id) {
            currentChannel = this.state.channels?.find(
                (o: PaymentChannel) =>
                    o.id === this.transaction.payment_channel_id
            );
        }
        return (
            <>
                <div hidden={this.state.showAllChannel}>
                    <div className="mb-2">
                        <FormattedMessage
                            id="selectedPaymentMethodTitle"
                            defaultMessage={"Metode Pembayaran terpilih:"}
                        />
                        <br />
                        <div className="d-flex align-items-center">
                            {currentChannel && currentChannel?.logo_url && (
                                <div className="flex-shrink-1">
                                    <img
                                        src={currentChannel?.logo_url}
                                        width={
                                            currentChannel?.is_bank_transfer
                                                ? 48
                                                : 20
                                        }
                                        className="mr-1"
                                        alt="logo"
                                    />
                                </div>
                            )}
                            <div>
                                <b>{this.transaction.payment_channel_name}</b>
                            </div>
                            {currentChannel &&
                                currentChannel.name === "OVO" && (
                                    <div className="ml-2">
                                        <button
                                            type="button"
                                            hidden={this.state.isDelayRetry}
                                            className="btn btn-link btn-sm"
                                            onClick={() =>
                                                this.triggerPhoneInputBeforePayment()
                                            }
                                        >
                                            <FormattedMessage
                                                id="retryPayment"
                                                defaultMessage={"Coba lagi"}
                                            />
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn btn-success w-100 mt-2"
                        onClick={() => {
                            this.setState({ showAllChannel: true });
                        }}
                    >
                        <FormattedMessage
                            id="changePaymentMethod"
                            defaultMessage={"Ganti Metode Pembayaran"}
                        />
                    </button>
                </div>
                <Button
                    hidden={!this.state.showAllChannel}
                    disabled={
                        this.state.isFormLoading || this.state.isFormInvalid
                    }
                    variant="success"
                    type="submit"
                    className="mt-3 btn-block"
                >
                    {label}
                </Button>
            </>
        );
    }

    render() {
        return (
            <div className="card mb-1">
                {!this.state.isProcessingPayment && (
                    <div className="card-body">
                        {this.renderTransactionDetail()}
                    </div>
                )}
                {this.state.isProcessingPayment && (
                    <div id="loadingWrapper">
                        <div className="loader">
                            {!this.transaction.is_bank_transfer && (
                                <>
                                    <FormattedMessage
                                        id="paymentBeingProcessed"
                                        defaultMessage={
                                            "Pembayaran Anda sedang diproses, silahkan kembali ke halaman registrasi"
                                        }
                                    />
                                    <a
                                        className="btn btn-outline-success mt-2"
                                        href={getRedirectUrl()}
                                    >
                                        <FormattedMessage
                                            id="backToRegistrationForm"
                                            defaultMessage={
                                                "Kembali ke halaman registrasi"
                                            }
                                        />
                                    </a>
                                </>
                            )}
                            {this.transaction.is_bank_transfer && (
                                <>
                                    <FormattedMessage
                                        id="paymentBeingProcessedWait"
                                        defaultMessage={
                                            "Pembayaran Anda sedang diproses, silahkan menunggu"
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export const PaymentWidget = injectIntl(PaymentWidgetWithIntl);
